.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#connect_wallet {
  float: right;
  margin-right: 10px;
}

#proj_Name {
  margin-left: 10px;
}

#wallet_addr {
  width: 10vw;
  float: right;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

#create_button {
  text-align: right;
}

#proj_Name {
  color: white;
}

.card_content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.mainContent {
  width: 80vw;
  margin: 10vh auto;
}

.mainClass {
  width: 85vw;
  margin: 2vh auto;
}

.card_img {
  width: 80%;
  height: 80%;
  margin: auto;
}

/*body {
  background-image: url('./background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}*/

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
      transform: translateX(1);
  }

  25% {
      transform: translateX(-25%);
  }

  50% {
      transform: translateX(-50%);
  }

  75% {
      transform: translateX(-25%);
  }

  100% {
      transform: translateX(1);
  }
}
